<template>
  <div class="m-view-dataCollect">
    <pop-top :black="true" class="header"></pop-top>
    <div class="title flex-column">
      <span>频道数据统计</span>
      <span class="color-text-theme">/ Channel data statistics</span>
    </div>

    <h2>访问统计</h2>
    <div class="count">
      <div class="count-item flex-column">
        <div class="space-between" style="align-items: flex-start">
          <p class="count-item-title">访问次数</p>
          <span class="mask-text">Visits</span>
        </div>
        <div class="space-between">
          <p>
            <span class="number">{{ this.totalCount }}</span>
            <span class="unit">次</span>
          </p>
          <img src="@/assets/img/channel/time-icon.png" alt="" srcset="" />
        </div>
      </div>
      <div class="count-item flex-column">
        <div class="space-between">
          <p class="count-item-title">访问人数</p>
          <span class="mask-text">Number</span>
        </div>
        <div class="space-between">
          <p>
            <span class="number">{{ this.peopleCount }}</span
            ><span class="unit">人</span>
          </p>
          <img src="@/assets/img/channel/group-icon.png" alt="" srcset="" />
        </div>
      </div>
    </div>
    <h2>参与详情</h2>

    <Table
      :data="list"
      class="data-table"
      :row-class-name="tableRowClassName"
      v-loading="loading"
    >
      <TableColumn prop="user_name" label="用户名称">
        <template slot-scope="scope">
          <span class="name-column">{{ scope.row.user_name }}</span>
        </template>
      </TableColumn>
      <TableColumn prop="phone" label="用户手机号">
        <template slot-scope="scope">
          <span class="phone-column">{{ scope.row.phone }}</span>
        </template>
      </TableColumn>
      <TableColumn prop="update_time" label="访问时间">
        <template slot-scope="scope">
          <span class="time-column">{{
            timeFormat(scope.row.update_time)
          }}</span>
        </template>
      </TableColumn>
    </Table>

    <van-pagination
      v-model="searchOption.page"
      :items-per-page="searchOption.per_page"
      :total-items="total"
      :show-page-size="4"
      @change="fetchData"
      v-show="total"
      class="van-pagination-theme"
    >
      <template #prev-text>
        <van-button>
          <van-icon name="arrow-left" />
        </van-button>
      </template>
      <template #next-text>
        <van-button>
          <van-icon name="arrow" />
        </van-button>
      </template>
      <template #page="{ text }">
        <van-button :class="{ active: searchOption.page == text }">
          {{ text }}
        </van-button>
      </template>
    </van-pagination>
  </div>
</template>

<script>
import PopTop from "@/components/PopTop.vue";
import { Table, TableColumn } from "element-ui";
function dateTimeFormat(date) {
  let format = "MM-dd hh:mm:ss";
  if (date !== "Invalid Date") {
    var o = {
      "M+": date.getMonth() + 1, //month
      "d+": date.getDate(), //day
      "h+": date.getHours(), //hour
      "m+": date.getMinutes(), //minute
      "s+": date.getSeconds(), //second
      "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
      S: date.getMilliseconds(), //millisecond
    };
    if (/(y+)/.test(format))
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o)
      if (new RegExp("(" + k + ")").test(format))
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return format;
  }
  return "";
}
export default {
  components: { PopTop, Table, TableColumn },
  data() {
    return {
      loading: true,
      finished: false,
      list: [],
      searchOption: {
        page: 1,
        per_page: 7,
        channel_id: this.$route.query.id,
      },
      total: 0,
      peopleCount: 0,
      totalCount: 0,
    };
  },
  created() {
    this.$http
      .get("/channel-manage/count", {
        channel_id: this.searchOption.channel_id,
      })
      .then(({ data }) => {
        if (data.length) {
          this.totalCount = data[0].total;
          this.peopleCount = data[0].people;
        }
      });
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get("/channel-manage/get-access", this.searchOption)
        .then(({ data }) => {
          this.list = data.data;
          this.total = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    timeFormat(time) {
      return dateTimeFormat(new Date(time), "MM-dd hh:mm:ss");
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2) {
        return "";
      } else {
        return "dart-row";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .m-view-dataCollect {
    padding: 0 5.33vw;
    padding-bottom: 10vh;
    background-image: url("https://sct-upload.oss-cn-shenzhen.aliyuncs.com/metaverse_meeting/activity_my/my_activity_bg_pc.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-top: 20.27vw;
    .header {
      //   height: 12vw;
      background-color: #fff;
    }
    * {
      box-sizing: border-box;
    }
    h2 {
      font-size: 4.27vw;
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 500;
      color: #3a3a3a;
      line-height: 5.2vw;
      margin-bottom: 4vw;
      position: relative;
      padding-left: 3.2vw;
      &::before {
        content: "";
        width: 0.8vw;
        height: 4.27vw;
        background: #7076ff;
        border-radius: 1.33vw;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    .title {
      row-gap: 1.3vw;
      margin-bottom: 10vw;
      span {
        &:first-child {
          font-size: 5.6vw;
          font-weight: 500;
          color: #252727;
          margin-right: 20px;
          line-height: 1;
        }
        &:nth-child(2) {
          font-size: 5.07vw;
          font-weight: bold;
        }
      }
    }
    .count {
      width: 100%;
      display: flex;
      column-gap: 3.5vw;
      height: 20vw;
      margin-bottom: 8vw;

      .count-item {
        flex: 1;
        height: 100%;
        box-shadow: 0px 0px 2vw 0.05vw rgba(173, 174, 198, 0.2);
        padding: 2.67vw;
        justify-content: space-between;
        .space-between {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .number {
            font-size: 5.07vw;
            font-weight: 700;
            color: #333333;
          }
          .unit {
            font-size: 2.67vw;
            font-weight: 400;
            color: #999999;
          }
        }
        .mask-text {
          font-size: 4.53vw;
          font-weight: 900;
          color: #f6f7f9;
          line-height: 3.73vw;
        }
        .count-item-title {
          font-size: 3.73vw;
          font-weight: 400;
          color: #666666;
        }
        img {
          height: 8vw;
          object-fit: contain;
        }
      }
    }

    .data-table {
      font-size: 3.2vw;
      line-height: 1;
      box-shadow: 0px 40px 100px 0px rgba(173, 174, 198, 0.6);
      margin-bottom: 8vw;
      ::v-deep .el-table__header-wrapper {
        .cell {
          font-size: 3.2vw;
          font-weight: 400;
          color: #666666;
        }
      }
      ::v-deep .el-table__body-wrapper {
        .cell {
          font-size: 3.2vw;
          line-height: 1;
        }
        .phone-column,
        .time-column {
          font-size: 2.67vw;
          font-weight: 350;
          color: #333333;
        }
        .name-column {
          font-size: 3.2vw;
          font-weight: 500;
          color: #333333;
        }
        .dart-row {
          background-color: #f5f6f9;
        }
      }
      ::v-deep .el-table__cell {
        padding-top: 4vw;
        padding-bottom: 4vw;
        border: 0;
      }
    }
  }
}
</style>
